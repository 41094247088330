import { memo, useCallback } from "react";

import Spinner from "@qoohoo/common/library/loader/Spinner";
import Modal from "@qoohoo/common/library/modals/Modal";
import useStore from "../../../store/store";

const FullScreenLoader = () => {
  const loader = useStore((state) => state.loader);
  const setLoaderState = useStore((state) => state.setLoaderState);

  const closeLoader = useCallback(() => {
    setLoaderState(false);
  }, [setLoaderState]);

  return (
    <Modal isOpen={loader} closeModal={closeLoader}>
      <div className="fixed bottom-0 left-0 z-20 inline-flex items-center justify-center w-full min-h-screen min-w-screen">
        <Spinner size="lg"/>
      </div>
    </Modal>
  );
};

export default memo(FullScreenLoader);
